(function ($) {

  "use strict";

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function () {
    $(document).visualImpairment();

    var scene = $('.scene');

    if (scene.length) {
      new Parallax(scene.get(0));
    }

    $('.nav-institutions .nav > li > a').on('click', function (e) {
      e.preventDefault();
    });

    $('.nav-institutions .nav .institution').each(function () {
      var $li = $(this),
        num = $li.attr('class').match('institution-(\\d+)')[1];

      $li.prepend('<img class="img-fluid" alt="" src="/wp-content/themes/culture/assets/images/institution_' + num + '.png">');
    });

    $('header.banner .emblem').each(function () {
      $(this).animate({
        top: -120
      }, 1200, 'easeInOutBack');
    });

    $('.menu-item-has-children > a').on('click', function (e) {
      e.preventDefault();
    });

    $('a[href^=\'#\']').on('click', function (e) {
      e.preventDefault();
      var href = $(this).attr("href");
      $('html, body').animate({scrollTop: $(href).offset().top + 'px'});
    });

    $('.nav-sidebar .menu-item-has-children > a').on('click', function () {
      var self = $(this),
        menuItem = self.parent();

      if (menuItem.hasClass('active')) {
        menuItem.removeClass('active');
      } else {
        $('.nav-sidebar .menu-item-has-children').removeClass('active');
        menuItem.addClass('active');
      }
    });
  });

  $(window).load(function () {
    $('.kult47-ru .nav-primary .menu-item-has-children').each(function () {
      var $self = $(this),
        $a = $self.children('a'),
        $ul = $self.children('ul');

      $a.css({
        minWidth: $ul.width()
      });
    });

    var menuTimeout;

    $('.nav-primary .menu-item-has-children').hover(function () {
      $('.nav-primary .menu-item-has-children .sub-menu')
        .not($(this).parent('.sub-menu'), $(this).find('.sub-menu'))
        .css('display', 'none');

      $(this).children('.sub-menu').css('display', 'block');

      clearTimeout(menuTimeout);
    }, function () {
      var el = $(this);

      menuTimeout = setTimeout(function () {
        el.children('.sub-menu').css('display', 'none');
      }, 1000);
    });
  });

})(jQuery); // Fully reference jQuery after this point.
